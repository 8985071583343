import Vue from 'vue';
import Vuex from 'vuex';
import Web3 from 'web3';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
//模块化store
// import Sys from '../components/modal/store';
Vue.use(Vuex);
import coin from '../api/coin';
export default new Vuex.Store({
  state: {
    provider: {},
    account: '',
    net: 0,
    gasPrice: 0,
    gasPriceType:3,
    slippage: 0.1,
    deadline: 20,
    multipath: true,
    transactions: {},
    block: 0,
    expert: false,
    
    netWorkConfig:  {
      key: 'OKT',
      NetWorkName: 'OKT Chain',
      logoURI: require('@/assets/tokenlogo/OKT.png'),
      Url: 'https://exchainrpc.okex.org/',
      blocsUrl: 'https://www.oklink.com/zh-cn/okc/tx/',
      blocsHashUrl: 'https://www.oklink.com/zh-cn/okc/token/',
      swapConfig: {
        jackRouter: '0xcd4f01b62480926cc4614ea44fb1f1e5f449485e',
        jackFactory: '0x7d72a61f5829b3cb271e6ff4c126f22bd7ffe166',
        weth: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
      },
      chainId: 66,
      TokenBNB: {
        name: 'OKT',
        symbol: 'OKT',
        address: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
        chainId: 66,
        decimals: 18,
        logoURI: require('@/assets/img/OKT.png'),
      }
    },
  },
  mutations: {
    SETPROVIDER: (state, provider) => {
      state.provider = provider;
    },
    SETGASTYPE: (state, gasPriceType) => {
      state.gasPriceType = gasPriceType;
    },
    SETNETWORKCINFIG: (state, netWorkConfig) => {
      state.netWorkConfig = netWorkConfig;
    },
    SETBLOCK: (state, block) => {
      state.block = block;
    },
    DELETETRANSACTIONS: state => {
      state.transactions = {};
    },
    SETEXPORT: (state, provider) => {
      state.expert = provider
    },
    SETTRANSACTIONS: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash] = transactionsDetail;
      state.transactions = tempObj;
    },
    SETTRANSACTIONSRECEIPT: (state, transactionsDetail) => {
      let tempObj = JSON.parse(JSON.stringify(state.transactions));
      tempObj[transactionsDetail.hash].receipt = transactionsDetail.receipt;
      state.transactions = tempObj;
    },
    SETACCOUNTS: (state, account) => {
 
      state.account = account;
      let data = {
        address: account
      }
      coin.getAccount(data).then(res => {
        if (res.code == 200) {
        }
      });

    },
    SETNET: (state, net) => {
      state.net = net;
    },
    SET_GASPRICE(state, gasPrice) {
      state.gasPrice = gasPrice;
    },
    SET_SLIPPAGE(state, slippage) {
      state.slippage = slippage;
    },
    SET_DEADLINE(state, deadline) {
      state.deadline = deadline;
    },
    SET_MULTIPATH(state, multipath) {
      state.multipath = multipath;
    },
    SET_EXPORT(state, ex) {
      state.expert = ex
    }
  },
  actions: {
    async setWallet() {
      var web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          await web3Provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                "chainId": String(this.state.netWorkConfig.chainId),
                "chainName": this.state.netWorkConfig.NetWorkName,
                "nativeCurrency": {
                  "name": this.state.netWorkConfig.TokenBNB.name,
                  "symbol": String(this.state.netWorkConfig.TokenBNB.name),
                  decimals: this.state.netWorkConfig.TokenBNB.decimals,
                },
                rpcUrls: [this.state.netWorkConfig.Url],
                blockExplorerUrls: [`https://www.oklink.com/okexchain`],
              },
            ],
          });
          await web3Provider.enable();
        } catch (error) {
          console.error('User denied account access');
        }
      }
    },
    async setWebProvider({ commit }) {
      var web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          // 请求用户授权
          await web3Provider.request({
            method: 'eth_requestAccounts',
            params: [
              {
                chainId: this.state.netWorkConfig.chainId,
                chainName: this.state.netWorkConfig.NetWorkName,
                nativeCurrency: {
                  name: this.state.netWorkConfig.key,
                  symbol: this.state.netWorkConfig.key,
                  decimals: 18,
                },
                rpcUrls: [this.state.netWorkConfig.Url],
                blockExplorerUrls: [`https://www.oklink.com/okexchain`],
              },
            ],
          });
          // await web3Provider.request({
          //   method: 'wallet_addEthereumChain',
          //   params: [
          //     {
          //       chainId: '0x3',
          //       chainName: 'Ropsten 测试网络',
          //       nativeCurrency: {
          //         name: 'ETH',
          //         symbol: 'eth',
          //         decimals: 18,
          //       },
          //       rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
          //       blockExplorerUrls: [`https://ropsten.etherscan.io`],
          //     },
          //   ],
          // });
          await web3Provider.enable();
        } catch (error) {
          // 用户不授权时
          console.error('User denied account access');
        }
        const web3js = new Web3(web3Provider); //web3js就是你需要的web3实例
        commit('SETPROVIDER', web3js);
        web3js.eth.net.getId(function (error, result) {
          if (!error) {
            console.log(result, '网络'); //授权成功后result能正常获取到账号了
            commit('SETNET', result);
          }
        });
        web3js.eth.getAccounts(function (error, result) {
          if (!error) {
            console.log(result, '账号'); //授权成功后result能正常获取到账号了
            commit('SETACCOUNTS', result[0]);
          }
        });
        web3Provider.on('networkChanged', function (networkIDstring) {
          commit('SETNET', networkIDstring);

        });
        web3Provider.on('accountsChanged', function (accounts) {
          commit('SETACCOUNTS', accounts[0]);

          console.log('切换账号', accounts);
        });
      }
    },
  },
  plugins: [
    createPersistedState({
      paths: ['gasPrice', 'slippage', 'deadline', 'multipath', 'transactions', 'block', "expert","netWorkConfig","gasPriceType"],
    }),
  ],
});
